@import url('https://fonts.googleapis.com/css2?family=Quantico:wght@400;700&display=swap');

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#videoPlayer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  z-index: -1;  /* This ensures the video stays behind other content */
}

.content {
  position: relative;
  z-index: 1;  /* This ensures content stays above the video */
  color: white; /* Makes text visible against dark video */
  text-align: center;
}

.welcome-page-title {
  position: fixed;
  top: 27%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.welcome-page-text {
  position: fixed;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
}

.start-page-buttons {
  position: fixed;
  bottom: 25%;  /* Adjust this value as needed */
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  gap: 160px;
}

.start-page-buttons button {
  font-size: 24px;
  padding: 15px 30px;
}

.result-page-buttons button {
  background-color: rgba(255, 255, 255, 0.3);
}

.start-page {
  margin-top: 0px;
}

.result-page {
  font-size: 48px;
  font-family: monospace;
}

#startButton {
  position: fixed;
  bottom: 35%;
  left: 70%;
  font-size: 24px;
  padding: 15px 30px;
  opacity: 0;  /* Start fully transparent */
  animation: fadeIn 1s ease-in forwards; 
}

#nextButton {
  position: fixed;
  bottom: 23%;
  left: 70%;
  font-size: 24px;
  padding: 15px 30px;
  opacity: 0;  /* Start fully transparent */
  animation: fadeIn 1s ease-in forwards; 
}

#muteButton {
  position: fixed;
  top: 20px;
  right: 20px;
  height: 40px;
  padding: 8px 10px;
}

#backButton {
  position: fixed;
  bottom: 13%;
  left: 70%;
  font-size: 24px;
  padding: 15px 30px;
  width: 238px;
  opacity: 0;
  animation: fadeIn 1s ease-in forwards; 
}

#restartButton {
  margin-right: 0;  /* Remove margin since we're using gap */
}

#skipButton svg {
  width: 36px;  /* Increase from default size */
  height: 36px; /* Increase from default size */
}

#skipButton {
  height: 40px;
  width: 46px;
  padding: 2px 6px;
}

#restartButton, #replayButton {
  height: 40px;
  width: 46px;
  padding: 8px 10px;
}

.layout-buttons {
  position: fixed;
  top: 20px;
  left: 20px;
  display: flex;
  gap: 10px;  
  opacity: 1;
}

.start-page-buttons {
  display: flex;
  justify-content: center;
  gap: 160px;
  font-size: 36px;
}

button {
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
  color: white;
  z-index: 1000;
}

h1 {
  font-size: 60px;
  margin: 0;
}

h2 {
  font-size: 44px;
  margin: 0;
}

button:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.result-page {
  font-size: 48px;
  font-family: monospace;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.result-page-buttons button {
  width: 238px;
}

.result-text {
  margin-left: 80px;  /* Adjust this value to control how far from the left edge */
  text-align: left;
  opacity: 0;  /* Start fully transparent */
  animation: fadeIn 1s ease-in forwards; 
}

.result-page h1 {
  font-size: 68px;
  font-family: 'Quantico', sans-serif;
  text-align: left;
  margin: 0;
}

.petal-page {
  font-size: 48px;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.petal-page p {
  font-size: 32px;
  font-family: 'Quantico', sans-serif;
  text-align: left;
  margin-left: 80px;  /* Same margin as result-text */
  line-height: 1.7;    /* Adds some spacing between lines */
}
